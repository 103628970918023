<template>
  <div v-if="drawer" class="main-sidebar sidebar-style-2" style="background:white; overflow-y: scroll;">
    <aside id="sidebar-wrapper">
      <div class="sidebar-brand text-center row ma-0 logo-border" style="background-color: #39688e;">
        <a href="/">
          <img src="../../public/images/logo-cf-live.png" alt="" class="logo-image">
          <span class="logo-name pl-1 "> <span style="color:orangered"></span> ag.cf.live </span>
        </a>
        <div class="w-100 row ma-0" style="justify-content: center;">
          <div
            style="border-bottom: 1px solid white; width:90%; text-align: center; box-shadow: 0px 1px 5px #aeafb1, 0px -1px 5px #aeafb1;">
          </div>
        </div>
      </div>
      <v-list-group v-for="item in menu" :key="item._id" no-action mandatory color="lighten-1" @click="marker = !marker">
        <template v-slot:activator>
          <v-icon class="icon-sidebar">{{item.action}} </v-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="font text-black"></v-list-item-title>
          </v-list-item-content>
      </template>
       
        <v-list-item v-for="child in item.items" :key="child.title" link :to="child.url">
          <v-icon style="margin-right: 5px !important;">
            {{ child.icon }}
          </v-icon>
          <v-list-item-content>
            <v-list-item-title v-text="child.title" class="font text-black"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </aside>
    <!-- <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      style="font-family: 'khmer mef1'"
      class="el1"
    >
      <v-list flat>
        <v-subheader style=""><b>Welcome</b></v-subheader>
      </v-list>
      <v-list class="side-menu">
        <v-list-group
          v-for="item in menu"
          :key="item.title"
          :prepend-icon="item.action"
          no-action
          mandatory
          color="black"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            link
            :to="child.url"
          >
            <v-icon style="margin-right: 5px !important">
              {{ child.icon }}
            </v-icon>
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["passUserInfo"],
  data: () => ({
    marker: true
  }),
  computed: {
    ...mapGetters("$_modules", {
      menu: "getMenu",
      user: "getUser",
      drawer: "getDrawer",
    }),
  },
  methods: {

    getUserCurrency() {
      if (this.passUserInfo.currency_type === 1) {
        return "$";
      } else if (this.passUserInfo.currency_type === 2) {
        return "៛";
      } else if (this.passUserInfo.currency_type === 3) {
        return "฿";
      }
    },
  },
};
</script>
<style scoped>
.font {
  font-family: 'SegoeUI', 'Koh Santepheap' !important;
  /* color: #dcdcdc; */
  font-size:14px;
}


.logo-image {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 5px;
}

.logo-name {
  letter-spacing: 1px;
  color: #dcdcdc;
  border-radius: 1px;
  font-weight: 300;
  border-left: 2px solid orangered;
}

.logo-border {
  color: white;
  border-radius: 1px;
  font-weight: 400;

}

.icon-sidebar {
  /* color: #dcdcdc; */
  padding-right: 10px;
  font-size: 14px;
}
.v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item{
  padding-left:30px;
}
a:hover {
    color: #0056b3;
    text-decoration: none!important;
}
 /* width */
 ::-webkit-scrollbar {
        width: 3px
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: rgb(255, 255, 255)
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: #b8860b;
        border-radius: 10px
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(78, 106, 181, .2)
    }
    .v-list-group--active{
      color: #39688e !important;
    }
</style>